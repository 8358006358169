/* Base Styles */
.base {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copybox {
    width: 100%;
    height: 5%;
    border-top: 1px solid rgb(34, 29, 29);
}

.copytext {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.007em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
}

.box1 {
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1%;
}

/* .imgbox {
    width: 100%;
    margin-top: 5%;
}

.imgbox div {
    width: 46px;
    height: 46px;
    border-radius: 50%;
}

.imgbox > img {
    width: 10%;
} */

.heading {
    /* font-family: 'Plus Jakarta Sans'; */
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 2.25vw;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: -0.014em;
    text-align: center;
    margin-top: 5%;
}

.singinform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    height: 30vh;
    margin-top: 1%;
}

.singinformbox {
    width: 100%;
    margin-top: 1%;
}

.singinformbox label {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 0.8vw;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}

.singinformbox .input-container {
    position: relative;
    width: 100%;
}

.singinformbox .inputbox {
    width: 100%;
    height: 48px;
    padding-left: 40px;
    padding-right: 12px;
    border-radius: 50px;
    border: 1px solid #CBD5E1;
    font-size: 14px;
    outline: none;
}

.singinformbox .icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

.formbox-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -3%;
}

.remember-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.remember-wrapper input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin: 0;
    cursor: pointer;
    accent-color: #2C67F2;
}

.remember-wrapper span {
    color: #64748B;
    font-size: 14px;
}

.forgot-link {
    color: #2C67F2;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.forgot-link:hover {
    color: #1a54e0;
    text-decoration: underline;
}

.submit {
    width: 100%;
    height: 45px;
    border-radius: 50px;
    background: #2C67F2;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 0 20px;
}

.submit:hover:not(:disabled) {
    background: #1a54e0;
}

.submit:disabled {
    background: #6691f6;
    cursor: not-allowed;
    opacity: 0.8;
}

.googlebox {
    width: 50%;
    margin-top: 2%;
}

.googlebutton {
    width: 100%;
    height: 48px;
    padding: 12px 20px;
    border-radius: 50px;
    border: none;
    background-color: black;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.googlebutton p {
    font-family: 'Archivo', sans-serif !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-left: 12px;
}

.singup {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

.border-between {
    width: 100%;
    position: relative;
    padding: 10px;
    text-align: center;
}

.border-between::before,
.border-between::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 40%;
    height: 1px;
    background-color: black;
}

.border-between::before {
    left: 0;
}

.border-between::after {
    right: 0;
}
/* Responsive Styling for Logos */
.imgbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    width: 100%;
    margin-right: 7%;
}

/* Logo - Adjust for screen sizes */
.imgbox div {
    width: 10%;
    height: 5%;
    border-radius: 50%;
}

.imgbox img:first-child {
    width: 10%;
    height: auto;
    margin-left: 30%;
}

/* Logo Group */
.imgbox img:last-child {
    width: 30%;
    height: auto;

}

/* Responsive Design for Specific Devices */

/* iPad Pro (820x1180 and 1024x1366) */
@media (min-width: 820px) and (max-width: 1366px) {
    .box1 {
        width: 60%;
    }
    .logo-group {
        display: flex;
        width: 70%;
        height: 100vh;
    }

    .heading {
        font-size: 1.8rem;
    }

    .singinform {
        width: 70%;
        height: 30vh;
    }

    .singinformbox .inputbox {
        height: 50px;
        padding-left: 30px;
    }

    .submit {
        font-size: 1rem;
    }
}
/* Surface Pro (912x1368) */
@media (min-width: 912px) and (max-width: 1368px) {
    .box1 {
        width: 65%;
    }

    .heading {
        font-size: 2rem;
    }

    .singinform {
        width: 75%;
        height: 32vh;
    }

    .singinformbox .inputbox {
        height: 52px;
        padding-left: 32px;
    }
}

/* General Responsive Design */
@media (max-width: 768px) {
    .box1 {
        width: 100%;
    }

    .heading {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .box1 {
        width: 100%;
    }
    .heading {
        font-size: 2.4rem;
    }

/* Responsive Design for 375x667 (Small Screens) */
@media (max-width: 375px) and (max-height: 667px) {
    .box1 {
        width: 95%;
        margin-top: 10%;
    }

    .heading {
        font-size: 1.7rem;
        margin-top: 8%;
    }

    .singinform {
        width: 90%;
        height: auto;
        margin-top: -3%;
    }

    .singinformbox {
        width: 100%;
        margin-bottom: -2%;
    }

    .singinformbox .inputbox {
        height: 40px;
        padding-left: 28px;
        font-size: 12px;
    }

    .singinformbox label {
        font-size: 0.9rem;
        margin-bottom: 1px;
    }

    .singinformbox .icon {
        font-size: 16px;
        left: 10px;
    }

    .formbox-2 {
        flex-direction: column;
        gap: 10px;
        margin-top: 1%;
    }

    .remember-wrapper span {
        font-size: 10px;
    }

    .submit {
        font-size: 20px;
        height: 40px;
    }

    .googlebox {
        width: 100%;
        margin-top: -2%;
    }

    .googlebutton {
        height: 44px;
        font-size: 20px;
    }

    .border-between::before,
    .border-between::after {
        width: 30%;
    }
}
/* Responsive Design for 375x667 (Small Screens) */
@media (max-width: 430px) and (max-height: 932px) {
    .box1 {
        width: 95%;
        margin-top: 10%;
    }

    .heading {
        font-size: 1.7rem;
        margin-top: 8%;
    }

    .singinform {
        width: 90%;
        height: auto;
        margin-top: -3%;
    }

    .singinformbox {
        width: 100%;
        margin-bottom: -2%;
    }

    .singinformbox .inputbox {
        height: 40px;
        padding-left: 28px;
        font-size: 12px;
    }

    .singinformbox label {
        font-size: 0.9rem;
        margin-bottom: 1px;
    }

    .singinformbox .icon {
        font-size: 16px;
        left: 10px;
    }

    .formbox-2 {
        flex-direction: column;
        gap: 10px;
        margin-top: 1%;
    }

    .remember-wrapper span {
        font-size: 10px;
    }

    .submit {
        font-size: 20px;
        height: 40px;
    }

    .googlebox {
        width: 100%;
        margin-top: -2%;
    }

    .googlebutton {
        height: 44px;
        font-size: 20px;
    }

    .border-between::before,
    .border-between::after {
        width: 30%;
    }

    .remember-wrapper {
        display: flex;
        align-items: start;
        gap: 8px;
        margin-right:58%;
    }
    
    .remember-wrapper input[type="checkbox"] {
        width: 16px;
        height: 16px;
        margin: 0;
        cursor: pointer;
        accent-color: #2C67F2;
       
    }
    
    .remember-wrapper span {
        color: #64748B;
        font-size: 14px;
    }
    
    .forgot-link {
        color: #2C67F2;
        font-size: 14px;
        text-decoration: none;
        font-weight: 500;
        transition: color 0.3s ease;
        margin-right:59%;

    }
    
    .forgot-link:hover {
        color: #1a54e0;
        text-decoration: underline;
    }
    .imgbox img:first-child {
        width: 30%;
    }
    .imgbox img:last-child {
        width: 55%;
    }
}

}

