form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    height: 35vh;
}

.setpasswordformbox {
    width: 100%;
}

.setpasswordformbox label {
    font-family: 'Plus Jakarta Sans';
    font-size: 0.8vw;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}

.setpasswordformbox .input-container {
    position: relative;
    width: 100%;
}

.setpasswordformbox .inputbox {
    width: 100%;
    height: 48px;
    padding-left: 40px;
    padding-right: 12px;
    border-radius: 50px;
    border: 1px solid #CBD5E1;
    font-size: 14px;
    outline: none;
}

.setpasswordformbox .icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

.success-message {
  text-align: center;
  padding: 2rem;
}

.success-icon {
  margin-bottom: 1.5rem;
}

.success-title {
  color: #4CAF50;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.success-text {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 300px;
  margin: 0 auto;
}

.error-message {
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
