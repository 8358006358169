.custom-scrollbar {
  overflow-y: scroll;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Thin scrollbar width */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #9FB8EF; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-track {
  /* background: ; Background color of the scrollbar track */
  border-radius: 10px; /* Rounded corners */
}   



/* Sidebar.css */

/* Container of the sidebar */
.sidebar {
    position: relative;
    background-color: #ecf2ff;
    height: 100%;
    transition: width 0.3s ease;
    z-index: 1;
  }
  
  /* Header of the sidebar */
  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 9%;
    padding-left: 15px;
    cursor: pointer;
  }
  
  /* Container for the logo image */
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right: 10px; */
    width: 46px;
    height: 46px;
    border-radius: 50%;
  }
  
  /* Logo image styles */
  .logo-container img {
    width: 80%;
    height: 80%;
  }
  
  /* Image visibility condition */
  .sidebar-header img {
    display: inline-block;
    width: 60%;
  }
  
  /* Content below the header */
  .sidebar-content {
    /* background-color: red; */
    height: 60%;
  }

  .hestoryHeading{
     /* transition: 'opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1)'; */
     opacity: 1;
     font-weight:600;
     font-size:'20px';
     color: #3E325C;
     font-family: 'Archivo', sans-serif !important;
     /* background-color: red; */
    }
  .heaster-content_text{
    margin-bottom: 6px;
    padding: 4px;
    font-size: 14px;
    color: #3E325C;
    opacity: 1;
    text-align: left;
    cursor: pointer;
    font-family: 'Encode Sans', sans-serif !important;
  }
  @media(min-width: 640px) and (max-width: 1124px) {
    .sidebar {
      width: 200px; /* Narrower width for tablets */
    }
  
    .sidebar-header {
      justify-content: space-between; /* Space out the logo and optional image */
      padding: 10px;
    }
  
    .sidebar-header > img {
      width: 80%;
      height: 80%;
    }
    .logo-container {
      width: 90px; /* Smaller logo container for tablet view */
      height: 90px;
      margin-right: 0px;
    }
    .logo-container img {
      width: 80%;
      height: 80%;
    }
  
    .sidebar-content {
      height: auto; /* Allow the content area to grow if needed */
    }
  }

/* Media Query for Tablet Devices (max-width: 768px) */
@media (max-width: 890px) {
  .sidebar {
    width: 200px; /* Narrower width for tablets */
  }

  .sidebar-header {
    justify-content: space-between; /* Space out the logo and optional image */
    padding: 10px;
  }

  .sidebar-header > img {
    width: 70%;
    height: 70%;
  }
  .logo-container {
    width: 90px; /* Smaller logo container for tablet view */
    height: 90px;
  }
  .logo-container img {
    width: 50%;
    height: 50%;
  }

  .sidebar-content {
    height: auto; /* Allow the content area to grow if needed */
  }

}

/* Mobile view styles */
@media (max-width: 780px) {
/* moblie menu */

/* Sidebar for Mobile */
.mobile-menu-icon {
  font-size: 28px;
  color: #2563EB;
  cursor: pointer;
  margin-top: 4%;
  margin-left: 4px;
}
.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 9%;
  cursor: pointer;
}
/* Container for the logo image */
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 10px; */
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

/* Logo image styles */
.logo-container img {
  width: 80%;
  height: 80%;
}

/* Image visibility condition */
.sidebar-header img {
  display: inline-block;
  width: 70%;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 9998; /* Below the sidebar */
  display: none; /* Hidden by default */
}

.sidebar-overlay.active {
  display: block; /* Show overlay when sidebar is open */
}

.sidebar.mobile-sidebar {
  position: fixed;
  top: 0;
  left: -100%; /* Hidden off-screen */
  height: 100%;
  width: 70%; /* Adjust width as needed */
  background-color: #ecf2ff;
  transition: left 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 9999;
}

.sidebar.mobile-sidebar.open {
  left: 0; /* Slide in when open */
}
}




