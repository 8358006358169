/* General container styles */
.maincontainer {
    width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    margin: 0;
    position: relative;
}

/* Main content section */
.main-content {
    width: 100%;
    /* background-color: red; */
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    /* justify-content: start; */
    align-items: flex-end;
}

.formbase{
    position: fixed; /* Ensures it hovers over the current page */
    top: 10%;
    right: 2%;
    z-index: 1000; 
    background-color: white;
    /* padding: 20px; */
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    /* max-width: 350px; */
    /* width: 100%; */
}

/* Header section */
.header {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: none; */
}

/* Header left content */
.header-left {
    width: 100%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 12%;
    /* background-color: firebrick; */
}

/* Icon circle styles */
.icon-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.icon-circle {
    width: 40px;
    height: 40px;
    background: linear-gradient(321.89deg, #62CFF4 5.13%, #2C67F2 82.07%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    box-shadow: 0px 2px 2px 0px #D0E4FF;

}

.icon-img {
    width: 24px;
    height: 24px;
}

/* Header text */
.header-text {
    font-size: clamp(0.875rem, 2vw, 1rem);
    font-weight: 700;
    margin: 0;
    color: #3E325C;
    font-family: 'Encode Sans', sans-serif !important;
}

/* Clear Response link */
.clear-response {
    font-size: clamp(0.75rem, 1.5vw, 0.875rem);
    white-space: nowrap;
    font-family: 'Archivo', sans-serif !important;
}

/* Questions container section */
.questions-container {
    width: 100%;
    height: 70vh;
    flex-grow: 1;
    overflow-y: scroll;
    padding: 1rem;
    display: flex;
    justify-content: center;
    /* background-color: firebrick; */
}
.question-style {
    margin-left: 5px;
    font-weight: 800;
    color: #3E325C;
    font-size: 16px;
    font-family: 'Archivo', sans-serif !important;
    display: inline-block; /* Ensure inline alignment */
    vertical-align: top; /* Align with list numbers */
}

.question-options {
    color: #3E325C;
    font-family: 'Archivo', sans-serif !important;
    margin-left: 5px; /* Indent options relative to the question */
    list-style-type: disc; /* Add a consistent list style */
    padding-left: 15px; /* Padding for better readability */
}

.question-answer {
    font-weight: 600;
    font-family: 'Archivo', sans-serif !important;
    color: #3E325C;
    margin-left: 5px; /* Align with options */
    padding-top: 5px; /* Space between a    nswer and options */
    display: block; /* Ensure it occupies a new line */
}

.renderquestion{
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: forestgreen; */
    /* overflow: auto; */
}
.rederbox1{
    width: 50%;
    /* height: 100%; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center; */
    /* background-color: red; */
    margin-right: 6%;
}
/* Footer section */
.footer {
    width: 100%;
    padding: 1rem 0;
    margin-top: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* gap: 2rem; */
}

/* Footer left content */
.footer-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* Footer icons */
.footer-icon {
    cursor: pointer;
    font-size: 1.25rem;
}
.footerbox{
    width: 100%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
    /* background-color: red; */
}

/* Regenerate response button */
.regenerate-button {
    color: #1B2559;
    background-color: white;
    border: 1px solid #06106f;
    border-radius: 30px;
    padding: 0.5rem 1rem;
    font-size: clamp(0.75rem, 1.5vw, 0.875rem);
    opacity: 0.5;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
    margin-right: 35%;
}

.regenerate-button:enabled {
    opacity: 1;
    pointer-events: auto;
}

/* Regenerate button icon */
.button-icon {
    width: 16px;
    height: 16px;
}

/* Side content section */
.side-content {
    width: 25%; 
    min-height: 80vh;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: sticky;
    top: 0;
    right: 0;
    background: #fff;
}



/* General container styles for tab view */
@media (min-width: 640px) and (max-width: 1124px) {
    .maincontainer {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;
    }

    .main-content {
        width: 100%; /* Keep the content width proportional */
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-end;
    }

    .formbase {
        position: fixed; /* Keep it floating in a fixed position */
        top: 10%;
        right: 2%;
        z-index: 1000;
        background-color: white;
        /* padding: 20px; */
        /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); Keep shadow for visibility */
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        /* max-width: 300px; */
        /* background-color: green; */
    }

    .header {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header-left {
        width: 100%;
        max-width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        /* background-color: gray; */
        margin-right: 9%;
    }

    .icon-circle {
        width: 40px;
        height: 40px;
    }

    .icon-img {
        width: 24px;
        height: 24px;
    }

    .header-text {
        font-size: clamp(0.9rem, 2.5vw, 1rem);
        font-weight: 700;
        color: #3E325C;
    }

    .clear-response {
        font-size: 0.85rem;
        white-space: nowrap;
    }

    .questions-container {
        width: 100%;
        height: auto;
        max-height: 60vh;
        overflow-y: auto; /* Ensure scrolling works well */
        padding: 1rem;
    }
    .renderquestion{
        width: 100%;
        /* height: 75vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* overflow-y:scroll ; */
    }
    .rederbox1{
        width: 70%;
        height: 100%;
        /* background-color: red; */
        /* overflow-y:scroll ; */
        /* display: flex;
        flex-direction: column;
        align-items: start;
        justify-content:start; */
    }

    .footer {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footerbox{
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        /* background-color: red; */
        margin: 0px;
    }
    .footer-left {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .footer-icon {
        cursor: pointer;
        font-size: 1.25rem;
    }

    .regenerate-button {
        color: #1B2559;
        background-color: white;
        border: 1px solid #06106f;
        border-radius: 30px;
        padding: 0.5rem 1rem;
        font-size: clamp(0.85rem, 1.5vw, 0.875rem);
        opacity: 1;
        pointer-events: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        white-space: nowrap;
        margin-right: 10%;
    }

    .side-content {
        width: 30%;
        min-height: 80vh;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: sticky;
        top: 0;
        right: 0;
        background: #fff;
    }
}


@media (max-width: 724px) {
    .maincontainer {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;
    }

    .main-content {
        width: 100%; /* Keep the content width proportional */
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-end;
    }

    .formbase {
        position: fixed; /* Keep it floating in a fixed position */
        top: 10%;
        right: 2%;
        z-index: 1000;
        background-color: white;
        /* padding: 20px; */
        /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); Keep shadow for visibility */
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        /* max-width: 300px; */
        /* background-color: green; */
    }

    .header {
        width: 100%;
        /* padding: rem 0; */
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .header-left {
        width: 100%;
        max-width: 80%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 1rem;
        /* background-color: gray; */
        margin-right: 9%;
    }

    .icon-circle {
        display: none;
        width: 0px;
        height: 0px;
    }

    .icon-img {

        width: 0%;
        height: 0px;
    }

    .header-text {
        font-size: clamp(0.9rem, 2.5vw, 1rem);
        font-weight: 700;
        color: #3E325C;
    }

    .clear-response {
        font-size: 0.7rem;
        white-space: nowrap;
    }

    .questions-container {
        width: 100%;
        height: auto;
        max-height: 60vh;
        overflow-y: auto; /* Ensure scrolling works well */
        padding: 1rem;
    }
    .renderquestion{
        width: 100%;
        /* height: 75vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* overflow-y:scroll ; */
    }
    .rederbox1{
        width: 100%;
        height: 100%;
        /* background-color: red; */
        /* overflow-y:scroll ; */
        /* display: flex;
        flex-direction: column;
        align-items: start;
        justify-content:start; */
    }

    .footer {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footerbox{
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        /* background-color: red; */
        margin: 0px;
    }
    .footer-left {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .footer-icon {
        cursor: pointer;
        font-size: 1.25rem;
    }

    .regenerate-button {
        color: #1B2559;
        background-color: white;
        border: 1px solid #06106f;
        border-radius: 30px;
        padding: 0.5rem 1rem;
        font-size: clamp(0.85rem, 1.5vw, 0.875rem);
        opacity: 1;
        pointer-events: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        white-space: nowrap;
        margin-right: 10%;
    }

    .side-content {
        width: 30%;
        min-height: 80vh;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: sticky;
        top: 0;
        right: 0;
        background: #fff;
    }
}

