.right-section {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headheader {
  background-color: #FFFFFF;
  width: 100%;
  height: 9%;
  padding: 2px;
  border-bottom: 1px solid #dee8fd;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-dropdown {
  position: relative;
  width: 140px;
  margin-left: 24px;
}

.dropdown-header {
  width: 100%;
  height: 4vh;
  background-color: #e8efff;
  border: none;
  border-radius: 8px;
  padding: 5px 15px;
  font-size: 0.875rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-arrow {
  margin-left: 10px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.dropdown-option {
  padding: 10px 15px;
  font-size: 0.9rem;
  color: #303972;
  cursor: pointer;
  /* border-radius: 30rem; */
  transition: background-color 0.2s;
  list-style-type: none;
}

.dropdown-option:hover {
  background-color: #f5f5f5;

}
.select-container {
  flex: 1;
  position: relative;
  margin-left: 2%;
  color: #303972;
  font-family: 'Archivo', sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  /* Remove max-width to allow dynamic width */
  /* max-width: 100px; */ 
  min-width: 100px; /* Optional: Set a minimum width */
}

.user-icon {
  width: 36px;
  height: 36px;
  border-radius: 60px;
  background-color: #1F2937;
  color: white;
  margin-right: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-icon svg {
  color: white;
  font-size: 30px;
}

.user-icon-container {
  position: relative;
  padding-right: 3rem;
}

.user-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  min-width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.user-dropdown .dropdown-item {
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.user-dropdown .dropdown-item:hover {
  background-color: #f8f9fa;
}

.user-dropdown .dropdown-item.text-danger:hover {
  background-color: #fff5f5;
  color: #303972;
}

.content-section {
  width: 100%;
  height: 91%;
  overflow-y: auto;
  box-sizing: border-box;
}

@media (max-width: 780px) {
  .user-icon-container {
    position: relative;
    padding-right: 0rem;
  }
  .user-icon {
    width: 36px;
    height: 36px;
    border-radius: 60px;
    background-color: #1F2937;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}