.container  {
  max-width: 800px;
  max-height: 80vh;
  margin: 0 auto;
  padding:20px 0px 10px 0px ;
  position: relative;
  transition: all 0.3s ease;
  width: 300px;
  border: 1px solid #ECF2FF;
  border-radius: 8px;
  /* height: 83vh; */
  /* max-height: 300px; */
  /* background-color: green; */
  box-shadow: 0px 4px 4px 0px #00000040;
  overflow-y:auto;
  }
 

  .minimize-icon {
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  font-size: 50px;
  padding-right:10px;
  color: #666;
  transition: color 0.2s ease;
  z-index: 10;
  /* background-color: red; */
  }
  
  .minimize-icon:hover {
  color: #4a72ff;
  }
  
  .container.minimized {
  width: 70px;
  /* padding: 15px; */
  height:70px;
  overflow: hidden; 
  margin: 0px;
  border: none;
  box-shadow: none;
  }
  
  .container.minimized .form-group {
  opacity: 0;
  }
 
  .formside-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  /* border: 1px solid #2563EB; */
  background-color: #C4D7FF;

  /* margin-right: 20px;   */
  display: flex;
  justify-content: center;
  align-items: center;
 }

 /* Side icon image */
 .formside-icon-img {
  width: 50%;
  height: 50%;
  
 }
  
  .form-group {
  display: flex;
  flex-direction: column;
  gap:5px;
  }
  
  .dropdown-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  border-bottom: 1px solid #ECF2FF;
  }
  
  .dropdown-container:nth-child(1) {
  z-index: 3;
  }
  
  .dropdown-container:nth-child(2) {
  z-index: 2;
  }
  
  /* Native select styling */
  .select-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;
  font-size: 16px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
  }
  
  .select-input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.6;
  }
  
  .class-select-container {
  position: relative;
  width: 100%;
  }
  
  .class-select-container .select-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;
  font-size: 16px;
  cursor: pointer;
  max-height: 200px;
  overflow-y: auto;
  } 
  
  .class-select-container .select-input option {
  padding: 8px 12px;
  cursor: pointer;
  }
  
  .class-select-container .select-input option:hover,
  .class-select-container .select-input option:focus {
  background-color: #f5f5f5;
  }
  
  /* Custom scrollbar for select */
  .class-select-container .select-input::-webkit-scrollbar {
  width: 6px;
  }
  
  .class-select-container .select-input::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
  }
  
  .class-select-container .select-input::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
  }
  
  .class-select-container .select-input::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
  }
  
  /* Custom select styling */
  .custom-select {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 8px;
  box-sizing: border-box;
  }
  
  .custom-select:hover {
  border-color: #4a72ff;
  box-shadow: 0 2px 4px rgba(74, 114, 255, 0.1);
  }
  
  .custom-select.disabled {
  opacity: 0.6;
  pointer-events: none;
  }
  
  .formselect-header {
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 24px;
  color: #1F2937;
  font-weight: 500;
  }
  
  .dropdown-list {
  width: 100%;
  background: #F5F8FF;
  border-top: 1px solid #e0e0e0;
  margin-top: 4px;
  color: #000000;
  font-weight: 400;
  }
  
  .dropdown-scroll {
  max-height: 200px;
  overflow-y: auto;
  padding: 4px 0;
  /* background-color: red; */
  }
  
  .dropdown-scroll::-webkit-scrollbar {
  width: 6px;
  }
  
  .dropdown-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
  }
  
  .dropdown-scroll::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
  }
  
  .dropdown-scroll::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
  }
  
  .class-option {
  padding: 10px 12px;
  transition: background-color 0.2s;
  }
  
  .class-option:hover {
  background-color: #f5f5f5;
  }
  
  .arrow {
  font-size: 12px;
  opacity: 0.6;
  transition: transform 0.2s;
  color: #1F2937;
  }
  
  /* .custom-select.open .arrow {
  transform: rotate(180deg);
  } */
  
  /* Subject option styling */
  .subject-option {
  position: relative;
  cursor: pointer;
  /* background-color:greenyellow; */
  }
  
  .subject-names {
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s;
  /* background-color: goldenrod; */
  }
  
  .subject-names:hover {
  background-color: #EBF1FF;
  }
  
  /* Title options (side dropdown) */
  .title-options {
  /* position: fixed;  */
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  z-index: 1001;
  display: none;  
  }
  
  .subject-option:hover .title-options {
  display: block;
  visibility: visible; /* Make visible on hover */
  opacity: 1; 
  }
  
  .title-option {
  padding: 10px 12px;
  transition: background-color 0.2s;
  cursor: pointer;
  white-space: nowrap;
  }
  
  .title-option:hover {
  background-color: #f5f5f5;
  }
  
  /* Chapter section */
  .chapter-container {
  margin-top: 16px;
  }
  
  .chapter-section {
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  }
  
  .chapter-header {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 12px;
  }
  
  .chapter-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  }
  
  .chapter-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  }
  
  .chapter-item.selected {
  background-color: #f0f4ff;
  }
  
  .chapter-item input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin: 0;
  }
  
  .chapter-item label {
  cursor: pointer;
  flex: 1;
  font-size: 14px;
  color: #333;
  user-select: none;
  }
  
  .chapter-actions {
  display: flex;
  justify-content: flex-end;
  }
  
  .apply-button {
  background: #4a72ff;
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  }
  
  .apply-button:hover {
  background: #3d61e4;
  }
  
  .apply-button:disabled {
  background: #e0e0e0;
  cursor: not-allowed;
  }
  
  .slider-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  padding: 0px 30px 10px 30px;
  margin-bottom: 1px solid #ECF2FF;
  }
  
  .slider-section label {
  font-size: 16px;
  color: #1F2937;
  font-weight: 500;
  margin-bottom: 15px;
  }

  .question-count-box {
    display: inline-block; 
    padding: 10px; 
    background-color: #fff;
    border: 2px solid #DCDCDC; 
    border-radius: 4px; 
    margin-left: 25%; 
    width: 50px; 
    text-align: center; 
  }
  
  
  .sideformslider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: #e0e0e0;
  outline: none;
  margin: 20px 0;
  transition: background 0.2s ease;
  }
  
  .sideformslider::-webkit-sideformslider-runnable-track {
  -webkit-appearance: none;
  height: 4px;
  border-radius: 2px;
  }
  
  .sideformslider::-webkit-sideformslider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background: white;
  border: 2px solid #4a72ff;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  transition: all 0.2s ease;
  }
  
  .sideformslider::-webkit-sideformslider-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 4px rgba(0,0,0,0.15);
  }
  
  .questions-slider {
  background: linear-gradient(to right, #4a72ff 0%, #4a72ff 50%, #e0e0e0 50%, #e0e0e0 100%);
  }
  
  .difficulty-slider {
  background: linear-gradient(to right, #4a72ff 0%, #4a72ff 0%, #e0e0e0 0%, #e0e0e0 100%);
  }
  
  .slider-ticks {
  position: relative;
  width: 100%;
  height: 5px;
  margin-top: -12px;
  }
  .slider-ticks {
    display: flex;
    justify-content: space-between;
  }
  
  .tick {
    width: 0.5px;
    height: 8px;
    background-color: #e0e0e0;
  }
  
  
  .slider-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 0 2px;
  color: #C4C1C1;
  font-size: 12px;
  }
  
  .difficulty-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  }
  
  .difficulty-labels span {
    color: #C4C1C1;
    font-size: 12px;
  }
  
  .difficulty-labels span.active {
  color: #4a72ff;
  font-weight: 500;
  }
  
  .generate-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.2s;
  }
  
  .generate-button:hover {
  background: #0056b3;
  }
  
  .icon {
  font-size: 18px;
  }
  
  .title-options {
  z-index: 1001;
  }
  
  .chapters-display {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  }
  
  .chapter-display-item {
  padding: 10px 12px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  color: #2c3e50;
  }
  
  .selected-chapters-display {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  }
  
  .selected-chapter {
  padding: 12px;
  background: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  color: #2c3e50;
  transition: background-color 0.2s;
  }
  
  .selected-chapter:hover {
  background: #f1f3f5;
  }
  
  .chapter-tags {
  margin-top: 16px;
  position: relative;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 24px;
  }
  
  .chapter-tags:hover {
  border-color: #4a72ff;
  box-shadow: 0 2px 4px rgba(74, 114, 255, 0.1);
  }
  
  .chapter-tags .formselect-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1F2937;
  font-size: 14px;
  }
  
  .chapter-tags .arrow {
  font-size: 12px;
  color: #666;
  }
  
  .formchapter-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;
  margin-right: 8px;
  }
  
  .formchapter-pill {
  background: #f0f4ff;
  color: #4a72ff;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  border: 1px solid #e0e8ff;
  cursor: pointer;
  transition: all 0.2s ease;
  }
  
  .formchapter-pill:hover {
  background: #e5ebff;
  border-color: #4a72ff;
  }
  
  .show-more-wrapper {
  position: relative;
  display: inline-block;
  }
  
  .chapter-pill.show-more {
  background: #eef2ff;
  color: #4a72ff;
  cursor: pointer;
  padding-right: 16px;
  padding-left: 16px;
  }
  
  .chapter-pill.show-more:hover {
  background: #e5ebff;
  border-color: #4a72ff;
  }
  
  .more-chapters-popup {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 8px 0;
  min-width: 220px;
  max-width: 300px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 1000;
  }
  
  .show-more-wrapper:hover .more-chapters-popup {
  opacity: 1;
  visibility: visible;
  }
  
  .popup-chapter {
  padding: 8px 16px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  
  .popup-chapter:hover {
  background: #f5f5f5;
  }
 
  .toggle-section {
  margin: 0px 20px 20px 30px;
  }
  
  .toggle-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  color: #1F2937;
  font-weight: 500;
  }
  
  .toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 26px;
  }
  
  .toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
  }
  
  .toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(128, 128, 128, 0.308);
  /* border: 1px solid black; */
  transition: .4s;
  border-radius: 34px;
  }
  
  .toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background: linear-gradient(318.31deg, #62CFF4 -38.89%, #2C67F2 81.86%);
  transition: .4s;
  border-radius: 50%;
  }
  
  
  .toggle-input:checked + .toggle-slider {
    background: rgba(128, 128, 128, 0.308);
  }
  
  
  .toggle-input:checked + .toggle-slider:before {
  transform: translateX(12px);
  border: none;
  }


/* Responsive for 11-inch Tab View */
@media (max-width: 1366px) and (min-width: 1024px) {

  .container {
    /* width: 100%; Full width with margin for better layout */
    max-width: 300px;
    padding: 15px 5px; /* Reduce padding to fit content */
    height: 80vh; /* Dynamic height based on viewport */
    overflow-y: auto; /* Add scroll for content overflow */
    /* background-color: chartreuse; */
  }

  .formside-icon-container {
    width: 1.8vw; /* Larger icons for easier interaction */
    height: 1.8vw;
  }

  .formside-icon-img {
    width: 80%;
    height: 80%;
  }

  .select-input {
    font-size: 14px; /* Slightly smaller font for select options */
    padding: 10px; /* Adjust padding for compactness */
  }

  .apply-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .toggle-section {
    margin: 10px; /* Reduce margin to fit content better */
  }

  .toggle-slider {
    width: 30px; /* Larger toggle for touch interaction */
    height: 20px;
  }

  .toggle-slider:before {
    width: 14px;
    height: 14px;
  }

  .chapter-container {
    margin-top: 12px;
  }

  .generate-button {
    background: #007bff;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.2s;
    }
}

/* @media(min-width: 1024px) {
  .formside-icon-container {
    width: 3vw;
    height: 3vw;
  }
} */

@media (max-width: 879px) {

  .container {
    width: 100%; /* Full width with margin for better layout */
    max-width: none;
    padding: 15px 5px; /* Reduce padding to fit content */
    height: 80vh; /* Dynamic height based on viewport */
    overflow-y: auto; /* Add scroll for content overflow */
    /* background-color: red; */
  }

  .formside-icon-container {
    width: 4vw; /* Larger icons for easier interaction */
    height: 4vw;
  }

  .formside-icon-img {
    width: 70%;
    height: 70%;
  }

  .select-input {
    font-size: 14px; /* Slightly smaller font for select options */
    padding: 10px; /* Adjust padding for compactness */
  }

  .apply-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .toggle-section {
    margin: 10px; /* Reduce margin to fit content better */
  }

  .toggle-slider {
    width: 30px; /* Larger toggle for touch interaction */
    height: 20px;
  }

  .toggle-slider:before {
    width: 14px;
    height: 14px;
  }

  .chapter-container {
    margin-top: 12px;
  }

  .generate-button {
    background: #007bff;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.2s;
    }
    .question-count-box {
      margin-left: 17%; 
    }
}

@media (max-width: 609px) {
  .formside-icon-container {
    width: 7vw; /* Larger icons for easier interaction */
    height: 7vw;
  }
}
