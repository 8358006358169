
.auth-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}



button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
}

.mainformcontainer {
  max-width: 600px;
  margin: 230px auto;
  padding: 46px 70px 46px 70px;
  background: white;
  border-radius: 16px;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05); */
  box-shadow: 5px 5px 21px 0px #00000040;

}
.mainformheading{
  color: #303972;
}
h1 {
  color: #2c3e50;
  margin-bottom: 30px;
  font-size: 22px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dropdown-row {
  display: flex;
  gap: 16px;
}

.dropdown {
  position: relative;
  display: inline-block;
  flex: 1;
}

.dropdown .arrow-down {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #666;
  pointer-events: none;
}

.select-input {
  width: 100%;
  padding: 16px;
  border: 1px solid #C9D9FF;
  border-radius: 8px;
  font-size: 15px;
  color: #333;
  background-color: #F7F9FF;
  cursor: pointer;
  outline: none;
  transition: all 0.2s;
  appearance: none;
  max-height: 25px;
}

.select-wrapper {
  position: relative;
  width:225px;
  max-width: 630px;
  background-color: #F7F9FF;
  border-radius: 8px;
  
}

.select-wrapper select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 40px !important;
}

.select-wrapper .arrow-down {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #666;
  pointer-events: none;
  display: flex;
  align-items: center;
  gap: 4px;
}

.select-wrapper:hover .arrow-down {
  color: #1976d2;
}

.select-input:focus + .floating-label + .arrow-down {
  color: #1976d2;
}

.select-header {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 18px 20px;
  border: 1px solid #C9D9FF;
  border-radius: 12px;
  cursor: pointer;
  min-height: 62px;
  font-size: 16px;
}

.header-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  margin-right: 8px;
}

/* .select-text {
  color: #666;
  font-size: 15px;
} */

.select-text {
  color: #3E325C;
  font-size: 15px;
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis for overflowed text */
  max-width: 100%; /* Ensure it doesn't stretch beyond the container */
  display: block; /* Ensure it behaves like a block element */
}
.header-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* padding-right: 30px; */
}

.header-chip {
  display: inline-flex;
  align-items: center;
  padding: 5px 12px;
  background-color: #e3f2fd;
  color: #1976d2;
  border-radius: 16px;
  font-size: 14px;
  gap: 6px;
  margin-top: 4px;
}

.header-chip .chip-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-chip .chip-remove {
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  opacity: 0.7;
}

.header-chip .chip-remove:hover {
  opacity: 1;
}

.select-header .arrow-down {
  font-size: 12px;
  color: #666;
  transition: transform 0.2s ease;
}

.select-header.open {
  border-color: #1976d2;
}

.select-header.open .arrow-down {
  transform: rotate(180deg);
}

.floating-label {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  padding: 0 4px;
  font-size: 16px;
  color: #6A637B;
  pointer-events: none;
  transition: all 0.2s ease;
  z-index: 1;
}

.floating-label.float {
  top: 8px;
  transform: translateY(0);
  font-size: 14px;
  color: #1976d2;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 8px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.select-option {
  position: relative;
  padding: 16px 20px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-option:hover {
  background-color: #f5f5f5;
}

.select-option.selected {
  background-color: #e3f2fd;
  color:#6A637B;
}

.arrow-right {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  opacity: 0.6;
}

.chapter-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f7f9fc;
  position: relative;
}

.chapter-container label {
  font-size: 12px;
  color: #6A637B;
  margin-bottom: 4px;
}

.chapter-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.chapter-pill {
  background: #e0e7ff;
  border: none;
  color: #1a73e8;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.chapter-pill.selected {
  background: #d2e3fc;
  color: #1a73e8;
}

.chapter-pill:hover {
  background: #c7d2fe;
}

.chapter-pill::before {
  content: '✖';
  margin-right: 6px;
  font-size: 12px;
  color: #1a73e8;
}

.chapter-dropdown-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #999;
}

.generate-btn {
  background: linear-gradient(318.31deg, #62CFF4 -38.89%, #2C67F2 81.86%);
  color: white;
  border: none;
  padding: 12px 34px;
  border-radius: 25px;
  font-size: 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s;
  align-self: flex-end;
  font-weight: 500;
}

/* .generate-btn:hover {
  background-color: #3367d6;
  transform: translateY(-1px);
} */

.generate-btn:active {
  transform: translateY(0);
}

.generate-icon {
  font-size: 18px;
}

.subjects-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.subject-item {
  position: relative;
  cursor: pointer;
}

.subject-name {
  padding: 0.5rem 1rem;
  background-color: #f0f0f0;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.subject-item:hover .subject-name {
  background-color: #e0e0e0;
}

.titles-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  min-width: 200px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
}

.title-item {
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
}

.title-item:hover {
  background-color: #f5f5f5;
}

.title-item.selected {
  background-color: #e3f2fd;
  color: #1976d2;
}

.subject-dropdown {
  position: relative;
}

.nested-select-container {
  position: absolute;
  top: 10px;
  left: 100%;
  margin-left: 10px;
  z-index: 1000;
}

.nested-select {
  position: absolute;
  left: calc(100% - 10px);
  top: -1px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  z-index: 1001;
  margin-left: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.nested-option {
  padding: 12px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nested-option:hover {
  background-color: #f5f5f5;
}

.nested-option.selected {
  background-color: #e3f2fd;
  color: #1976d2;
}

.mainformchapter-section {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chapter-select {
  width: 100%;
  max-width: 100%;
}

.chapter-options {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chapter-options .select-option {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chapter-options .select-option:hover {
  background-color: #f5f5f5;
}

.chapter-options .select-option.selected {
  background-color: #e3f2fd;
}

.chapter-options .checkmark {
  color: #1976d2;
  font-size: 16px;
}

.selected-chapters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 50px;
}

.chapter-chip {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #1976d2;
  color: white;
  border-radius: 20px;
  font-size: 14px;
  gap: 6px;
}

.chip-text {
  max-width: 100px;
  /* width: 100px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chip-remove {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  line-height: 1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #2C67F2;
  transition: background-color 0.2s;
}

.chip-remove:hover {
  /* background-color: rgba(255, 255, 255, 0.2); */
}

.chip-showless {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show-more-chip {
  background-color: #bbdefb;
  cursor: pointer;
}

.show-more-chip:hover {
  background-color: #90caf9;
}

.show-more-chip .chip-remove {
  display: none;
}


/* Mobile View Adjustments */
@media (max-width: 768px) {
  .auth-container {
    /* max-width: 100%; */
    display: inline;
    align-items: center;
    justify-content:center;
    padding: 15px;
  }

  .mainformcontainer {
    max-width: 600px;
    margin: 100px auto;
    padding: 20px;
    border-radius: 12px;
  }

  h1 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .form-container {
    gap: 6px;
  }

  .select-header {
    min-height: 50px;
    font-size: 14px;
  }

  .header-chip {
    font-size: 12px;
    padding: 4px 8px;
  }

  .dropdown-row {
    flex-direction: column;
    gap: 8px;
  }

  .select-input {
    padding: 12px;
    font-size: 14px;
  }

  .generate-btn {
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 20px;
  }

  .chapter-container {
    padding: 10px;
  }

  .chapter-pill {
    font-size: 12px;
    padding: 4px 8px;
  }

  .subjects-container {
    gap: 0.5rem;
  }

  .subject-name {
    font-size: 14px;
    padding: 0.4rem 0.8rem;
  }

  .title-item {
    font-size: 14px;
    padding: 0.4rem 0.8rem;
  }

  .chapter-options .select-option {
    padding: 6px 12px;
  }
  .select-wrapper {
    position: relative;
    width:260px;
    /* max-width: 500px; */
    background-color: #F7F9FF;
    border-radius: 8px;
    
  }
  .selected-chapters {
    padding: 0.4rem;
    min-height: 40px;
  }
  .nested-select-container {
    position: absolute;
    top: 10px;
    left: 100%;
    /* margin-left: 10px; */
    z-index: 1000;
  }
  .nested-select {
    position: absolute;
    left: calc(34%);
    top: -1px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    z-index: 1001;
    margin-left: 10px;
    max-height: 300px;
    overflow-y: auto;
  }
  .chapter-chip {
    font-size: 12px;
    padding: 4px 8px;
    gap: 4px;
  }

  .chip-remove {
    font-size: 16px;
    width: 18px;
    height: 18px;
  }
}
