/* Main.css */

.main-container {
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: start;
    justify-content: center;
    box-sizing: border-box;
    flex-wrap: wrap;
    margin-top: 10%;
  }
  
  .inner-container {
    width: 100%;
    max-width: 900px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }
  
  .text-gradient {
    background: linear-gradient(271.32deg, #62CFF4 -4.68%, #2C67F2 99.15%);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 0.1px;
    font-family: "Archivo", sans-serif;
    margin: 0px;
    text-align: center;
  }
  
  .main-title {
    background: linear-gradient(271.32deg, #62CFF4 -4.68%, #2C67F2 99.15%);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 2.7rem;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 3.5rem;
    font-family: "Archivo", sans-serif;
    margin: 0px;
    text-align: start;
    white-space:wrap;  /* Prevent wrapping */
    overflow: hidden;     /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis if too long */
    max-width: 100%;      /* Ensure it doesn't overflow container */
  }
  
  .cards-container {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    padding-top: 20px;
    box-sizing: border-box;
  }
  
  .card {
    flex: 1 1 250px;
    min-width: 100px;
    max-width: 300px;
    height: 250px;
    border-radius: 14px;
    box-sizing: border-box;
    background-color: #FBFBFB;
    border: 1px solid #ECF2FF;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    padding: 20px;
    align-items: flex-start;
    padding: 50px 10px 60px 20px;

  } 
  
  .card-icon {

    width: 25px;
    height: 25px;
    background: linear-gradient(321.89deg, #62CFF4 5.13%, #2C67F2 82.07%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-left: 5px;
    box-shadow: 0px 2px 2px 0px #D0E4FF;
  }
  
  .card-icon img {
    width: 80%;
    height: 80%;
  }
  
  .card-description {
    font-size: 0.98rem;
    font-weight: 400;
    letter-spacing: 0.1px;
    text-align: start;
    flex-grow: 0.5;
    color: #303972;
    /* background-color: red; */
    margin-bottom: 14px;
  }
  
  .card-button {
    min-width: 80%;
    width: fit-content;
    padding: 8px 16px;
    background-color: #FFFFFF;
    position: relative;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    /* margin: 0; */
    align-self: flex-start;
  }
  
  .card-button::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50px;
    padding: 1px;
    background: linear-gradient(272.47deg, #62CFF4 -4.13%, #2C67F2 95.04%);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
  
  .button-text {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: #2C67F2;
    white-space: nowrap;
  }


@media (max-width: 640px){
  .text-gradient {
    font-size: 1rem;
  }

  .main-title {
    font-size: 1.5rem;
  }
}