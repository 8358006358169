.main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 65vh;
    /* background-color: fuchsia; */
}

/* The box where the form content resides */
.fgpbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    max-width: 500px; /* Optional: max-width to prevent it from getting too wide */
    height: auto;
    max-height: 50%;
    /* background-color: aquamarine; */
    padding: 2rem;
    box-sizing: border-box;
}

/* Adjusts the second box with the heading and text */
.fgpbox2 {
    width: 100%;
    text-align: center;
}

/* Heading styling */
.fgpheading {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 2vw;  /* Use a relative font size */
    font-weight: 800;
    line-height: 44px;
    letter-spacing: -0.014em;
    text-align: center;
    margin-top: 6%;
}

/* Text under heading */
.fgptext {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
}

/* Styling for the form */
.fgpform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 500px;
    
}

/* Form box for each field */
.fgpformbox {
    width: 100%;
    margin-bottom: 16px;
}

/* Label styling */
.fgpformbox label {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 0.8vw;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}

/* Input styling */
.fgpformbox .input-container {
    position: relative;
    width: 100%;
}

/* Input box styles */
.fgpformbox .inputbox {
    width: 100%;
    height: 48px;
    padding-left: 40px;
    padding-right: 12px;
    border-radius: 50px;
    border: 1px solid #CBD5E1;
    font-size: 14px;
    outline: none;
}

/* Icon positioning inside input box */
.fgpformbox .icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

/* Submit button styling */
.fgpsubmit {
    width: 100%;
    height: 48px;
    padding: 12px 20px;
    border-radius: 50px;
    border: none;
    background-color: #2C67F2;
    color: #FFFFFF;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

/* Message styles */
.message {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin: 16px 0;
    text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px;
}

.message.success {
    background-color: #dcfce7;
    color: #166534;
    border: 1px solid #86efac;
}

.message.error {
    background-color: #fee2e2;
    color: #991b1b;
    border: 1px solid #fca5a5;
}

.back-to-login {
    margin-top: 20px;
}

.back-to-login a {
    color: #2c67f2;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px;
}

.loading-icon {
    font-size: 20px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spin {
    animation: spin 1s linear infinite;
}

.fgpsubmit:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Adjust for iPad (portrait and landscape) */
@media (max-width: 1024px) and (min-width: 768px) {
    .fgpheading {
        font-size: 5vw; /* Slightly smaller heading for tablet screens */
    }
    .fgptext {
        font-size: 14px; /* Slightly smaller text */
    }
    .fgpformbox label {
        font-size: 3.5vw; /* Dynamic font size for tablets */
    }
    .fgpsubmit {
        font-size: 14px; /* Adjust button text size */
    }
    
    .fgpbox {
        padding: 1.5rem; /* Reduced padding for better fit */
        max-width: 90%; /* Box width adjusts to 90% of the screen */
    }

    /* Ensure form doesn't stretch too wide */
    .fgpform {
        max-width: 450px;
    }
}

/* Adjust for smaller screens (smartphones) */
@media (max-width: 768px) {
    .fgpheading {
        font-size: 6vw; /* Smaller heading for smaller screens */
    }
    .fgptext {
        font-size: 14px; /* Slightly smaller text */
    }
    .fgpformbox label {
        font-size: 3.5vw; /* Dynamic font size for smaller screens */
    }
    .fgpsubmit {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .fgpheading {
        font-size: 8vw;
    }
    .fgptext {
        font-size: 12px;
    }
    .fgpformbox label {
        font-size: 4.5vw;
    }
    .fgpsubmit {
        font-size: 12px;
    }
}
