.base {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('/public/images/Bacground.svg');
    background-size: cover;
    background-position: center;
}

.copybox {
    width: 100%;
    height: 5%;
    border-top: 1px solid black;
}

.copytext {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.007em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
}

.box1 {
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.imgbox {
    width: 100%;
    margin-top: 2%;
}

.imgbox div {
    width: 46px;
    height: 46px;
    border-radius: 50%;
}

.imgbox > img {
    width: 20%;
}

.heading {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 2.25vw;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: -0.014em;
    text-align: center;
    margin-top: 6%;
}

.signupform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    height: 60vh;
}

.formbox {
    width: 100%;
}

.formbox label {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 0.8vw;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}

.formbox .input-container {
    position: relative;
    width: 100%;
}

.formbox .inputbox {
    width: 100%;
    height: 48px;
    padding-left: 40px;
    padding-right: 12px;
    border-radius: 50px;
    border: 1px solid #CBD5E1;
    font-size: 14px;
    outline: none;
}

.formbox .icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

.singinlink {
    width: 100%;
    text-align: center;
    margin-top: 0px;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 12px;
    color: #6B7280;
}

.singinlink a {
    color: #2C67F2;
    font-weight: 600;
    font-size: 12px;
}

.checkboxtext {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.password-strength{
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.passwordtext{
        
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 2%;

}

.submit-button {
    width: 100%;
    height: 48px;
    padding: 12px 20px;
    border-radius: 50px;
    border: none;
    background-color: #2C67F2;
    color: #FFFFFF;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 20px;
    margin-bottom: 8px;
}

.submit-button:hover {
    background-color: #1a54d9;
}

.submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.submit-button svg {
    font-size: 24px;
}

.loading-icon {
    font-size: 20px;
}

.spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.signin-link {
    text-align: center;
    margin: 16px 0;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px;
    font-weight:700 ;
    color: #1F2937;
}

.signin-link a {
    color: #2C67F2;
    text-decoration: none;
    font-weight: 600;
    margin-left: 4px;
}

.signin-link a:hover {
    text-decoration: underline;
}

.message {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 16px;
    text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px;
}

.message.success {
    background-color: #dcfce7;
    color: #166534;
    border: 1px solid #86efac;
}

.message.error {
    background-color: #fee2e2;
    color: #991b1b;
    border: 1px solid #fca5a5;
}

.googlebox {
    width: 50%;
    margin-top: 2%;
}

.googlebutton {
    width: 100%;
    height: 48px;
    padding: 12px 20px;
    border-radius: 50px;
    border: none;
    background-color: black;
    color: #FFFFFF;
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.googlebutton p {
    font-family: 'Archivo', sans-serif !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-align: center;
    margin: 0;
}

.singup {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #6B7280;
    text-align: center;
    margin-top: 12px;
}

.singup a {
    color: #2C67F2;
    font-weight: 600;
    text-decoration: none;
    font-size: 12px;
}

.singup a:hover {
    text-decoration: underline;
}

@media (max-width: 893px) {
    .box1 {
        width: 90%;
    }

    .heading {
        font-size: 4vw;
    }

    .signupform {
        width: 50%;
        height: auto;
    }

    .formbox label {
        font-size: 2vw;
    }

    .googlebutton p {
        font-size: 14px;
    }

    .copytext {
        font-size: 12px;
    }

    .imgbox {
        width: 100%;
        margin-top: 5%;
    }

    .googlebox {
        width: 100%;
    }

    .submit-button {
        opacity: 1;
    }
}

@media (max-width: 450px) {
    .heading {
        font-size: 5vw;
    }
    .signupform{
        width: 100%;
    }
    .formbox label {
        font-size: 4vw;
    }

    .imgbox {
        width: 100%;
        margin-top: 5%;
    }

    .googlebutton p {
        font-size: 14px;
    }

    .copytext {
        font-size: 10px;
    }
}