.mesbox{
    width: 30%;
    height: 40vh;
    /* background-color: rgb(197, 197, 15); */
}

.mesheading{
font-family: Plus Jakarta Sans;
font-size: 36px;
font-weight: 800;
line-height: 44px;
letter-spacing: -0.014em;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.message{
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0%;
}